module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://eb3773c69377443b98fa857cde350722@o1362387.ingest.sentry.io/6653811","sampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Star Trek Timelines DataCore","short_name":"DataCore","start_url":"/","background_color":"#3A3F44","theme_color":"#272B30","display":"standalone","icon":"src/images/logo.svg","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"eff1eba8873af42819e36d7b4b245dec"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
